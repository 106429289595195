<template>
  <section>
    <v-row justify="space-between">
      <v-col cols="auto"><strong> {{ title }}</strong></v-col>
      <v-col cols="auto">
        <FilterList
          @setFilter="setFilter"
          :filters="filters"
        />
      </v-col>
    </v-row>
    <v-row
      dense
      align="center"
    >
      <v-col cols="12" md="6">
        <v-btn
          color="primary"
          small
          class="mr-2"
          @click="$router.push(`/main/${contractBy}/create`)"
          v-if="userAccess.canCreate"
        >
          {{ textButtonCreate }}
        </v-btn>
        <v-btn
          color="primary"
          elevation="2"
          outlined
          small
          v-if="contractBy !== 'pengakhiran-kontrak' && userAccess.canCreate"
          @click="$router.push(`/main/${contractBy}/template`)"
        >
          {{ textButtonUpload }}
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-row
          dense
          justify="end"
          align="center"
        >
          <v-col
            v-if="contractBy === 'adendum-kontrak' || contractBy === 'pengakhiran-kontrak'"
            cols="12"
            sm="6"
          >
            <v-select
              hide-details
              class="caption"
              prepend-inner-icon="mdi-filter"
              outlined
              dense
              v-model="companyType"
              @change="fetchContractList({ typeSearch: 'companyType' })"
              :items="filterByItems"
              :placeholder="$_strings.order.FILTER"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              :placeholder="placeholder"
              class="display-2 caption"
              dense
              hide-details
              outlined
              v-model="companyName"
              @keyup.native.enter="fetchContractList({ typeSearch: 'companyName' })"
            >
              <template v-slot:prepend-inner>
                <v-icon @click="fetchContractList({ typeSearch: 'companyName' })">
                  mdi-magnify
                </v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import FilterList from './FilterList.vue';

export default {
  props: {
    filters: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: () => '',
    },
  },
  components: {
    FilterList,
  },
  data() {
    return {
      filterByItems: [
        {
          text: 'Semua Mitra Kerja',
          value: '',
        },
        {
          text: 'Transporter',
          value: 'transporter',
        },
        {
          text: 'Shipper',
          value: 'shipper',
        },
      ],
      companyType: this.$route.query.companyType || '',
      companyName: this.$route.query.companyName || '',
    };
  },
  computed: {
    contractBy() {
      const [, , path] = this.$route.path.split('/');
      return path;
    },
    textButtonCreate() {
      if (this.contractBy === 'adendum-kontrak') {
        return this.$_strings.addendumContract.label.ADDEDUM_SUBMISSIONS;
      }
      if (this.contractBy === 'pengakhiran-kontrak') {
        return this.$_strings.terminationContract.label.PAGE_NAME;
      }
      return this.$_strings.contract.label.ADD_CONTRACT;
    },
    textButtonUpload() {
      if (this.contractBy === 'adendum-kontrak') {
        return this.$_strings.addendumContract.label.DOWNLOAD_TEMPLATE_ADENDUM;
      }
      return this.$_strings.contract.label.DOWNLOAD_CONTRACT;
    },
    placeholder() {
      if (this.contractBy === 'kontrak-shipper') {
        return this.$_strings.contract.label.SEARCHING_PLACEHOLDER;
      }
      if (this.contractBy === 'kontrak-3pl') {
        return this.$_strings.contract.label.SEARCHING_3PL_PLACEHOLDER;
      }
      if (this.contractBy === 'adendum-kontrak') {
        return this.$_strings.addendumContract.label.SEARCHING_PLACEHOLDER;
      }
      return this.$_strings.terminationContract.label.SEARCHING_PLACEHOLDER;
    },
  },
  methods: {
    setFilter(filters) {
      const {
        contractNo,
        periodMonth,
        isActive,
        monthsExpired,
        approvalStatus,
        contractStatus,
      } = filters;
      const oldFilters = { ...this.filters };
      this.filters.contractNo = contractNo;
      this.filters.periodMonth = periodMonth;
      this.filters.monthsExpired = monthsExpired;
      this.filters.approvalStatus = approvalStatus;
      this.filters.contractStatus = contractStatus;
      this.filters.isActive = '';
      if (typeof isActive === 'boolean') {
        this.filters.isActive = isActive;
      }
      if (JSON.stringify(oldFilters) !== JSON.stringify(this.filters)) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            ...this.filters,
            page: 1,
          },
        });
      }
      this.$emit('fetchContractList');
    },
    fetchContractList({ typeSearch }) {
      this.filters[typeSearch] = this[typeSearch];
      if (this.$route.query[typeSearch] !== this[typeSearch]) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            [typeSearch]: this[typeSearch],
            page: 1,
          },
        });
      }
      this.$emit('fetchContractList');
    },
  },
};
</script>
